import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import '../components/Header/Header.scss'

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: {eq: "MessageWrap_Logo_2021.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

if (!data?.logoImage?.childImageSharp?.fluid) {
  return <div>Error: Please contact Grand Rapids Tech</div>
}

  return (
    <div className="mw__logo">
      <Image 
        fluid={data.logoImage.childImageSharp.fluid}
      />
    </div>
  )
}

export default Logo
