import React from 'react';
import { Grid, Cell } from '@trbl/react-css-grid';
import Logo from '../logo'
import './Header.scss';

const Header = () => {

  return (
    <div className="mw__header">
      <Grid>
        <Cell
          hStart={2}
          hSpan={12}
        >
          <Logo
          />
          <div style={{ margin: "15px 0"}}>
            <a href="https://messagewrap.com/" rel="noopener noreferrer">
              &lt; Back To Homepage
            </a>
          </div>
        </Cell>
      </Grid>
    </div>
  );
};

export default Header
